import React, { useState, ChangeEvent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import HeroProducts from '../../../assets/heros/hero-products.png';
import HeroBanner from '../../../components/HeroBanner';
import StyledReport from './styles';
import ControlledInput from '../../../components/ControlledInput';
import CreateCustomerModal from './components/CreateCustomerModal';
import environment from '../../../environment';

const DeviceEnrolmentReporting: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowDataStores = async (gridApiParam: GridApi) => {
    try {
      const res = await axios.post(
        `${environment.apiPathDeviceEnrolment}aztGetCustomerData`,
        {},
        {
          ...environment.paramsDeviceEnrolment
        }
      );
      setRowData(res.data);

      if (res.data.length === 0) {
        gridApiParam?.showNoRowsOverlay();
      }
    } catch (err: AxiosError | any) {
      setRowData([]);
      gridApiParam?.showNoRowsOverlay();
      toast.error(err.message);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api?.showLoadingOverlay();
    setGridApi(params.api);
    getRowDataStores(params.api);
  };

  const columnDefs: any = [
    {
      headerName: 'Customer Name',
      field: 'customerName'
    },
    {
      headerName: 'Customer ID',
      field: 'customerId'
    },
    {
      headerName: 'Customer Emails',
      field: 'customerEmails'
    },
    {
      headerName: 'Admin Emails',
      field: 'adminEmails'
    },
    {
      headerName: 'Creation Datetime',
      field: 'creationDatetime',
      hide: true
    }
  ];

  return (
    <>
      <HeroBanner
        title='Device Enrolment Reporting'
        background={HeroProducts}
      />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <CreateCustomerModal
            gridApi={gridApi}
            getRowDataStores={getRowDataStores}
          />
        </div>
        <div className='report__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            defaultColDef={{
              resizable: true,
              flex: 1,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            suppressRowClickSelection
            pagination
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
            excelStyles={[
              {
                id: 'stringType',
                dataType: 'String'
              }
            ]}
          />
        </div>
      </StyledReport>
    </>
  );
};

export default DeviceEnrolmentReporting;
